/**SLIDE BANNERS**/
[class^="control-arrow"]:before,
[class*="control-arrow"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.carousel-container {
  max-width: 1366px;
  margin: 0 auto;
  transition: all ease-in 0.2s;
}
.carousel-container .banner-container {
  display: block !important;
}
.carousel-slider .slide {
  background: transparent;
}
.carousel-slider .lazy-load-image-background.blur.lazy-load-image-loaded {
  margin: 0 -15px;
  max-height: 313px;
  overflow: hidden;
}
.carousel-container img.banner-img {
  width: 100%;
}
.carousel .control-dots {
  transform: translate(0%, -10px);
}
.carousel .control-dots .dot {
  width: 17px;
  height: 17px;
  box-shadow: none;
  border: solid 1px #6d6d6d;
  opacity: 1;
}
.carousel .control-dots .dot:focus {
  outline: 0;
}
.carousel .control-dots .dot.selected {
  background-color: #6d6d6d;
}
.carousel.carousel-slider {
  z-index: 1;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border: none;
  border-radius: 50%;
  padding: 10px;
  white-space: nowrap;
  text-align: center;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000001f;
}
.carousel .control-prev.control-arrow {
  left: 5%;
}
.carousel .control-next.control-arrow {
  right: 5%;
}
.carousel .control-prev.control-arrow:before {
  content: "\e802";
}
.carousel .control-next.control-arrow:before {
  content: "\e803";
}
.carousel.carousel-slider .control-arrow {
  opacity: 1;
  font-size: 300%;
}
.carousel.carousel-slider .control-arrow:hover {
  background-color: transparent;
}
/**FIM-SLIDE-BANNERS**/

/*LISTAGEM DE PRODUTOS*/
.group-products-content {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  z-index: 1;
  position: relative;
}
.group-products-title {
  padding: 10px;
  padding-left: 0;
  font-size: 21px;
  font-weight: 600;
}
.group-product-home {
  width: 100%;
  max-width: 248px;
  border-radius: 6px;
  border: solid 1px #e4e4e4;
  margin: 0 5px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 0px 20px #ededed;
  background-color: #ffffff;
}
.group-product-img {
  width: 100%;
  text-align: center;
  margin: auto;
  padding: 15px 20px;
}
.group-product-img img {
  width: auto;
  max-width: 100%;
}
.group-product-details {
  width: 100%;
}
.group-product-price {
  font-size: 1.7rem;
  font-weight: 400;
}
.group-product-name {
  font-size: 17px;
  color: #a2a9ae;
  font-weight: 300;
}
.skeleton-group-banner .react-loading-skeleton {
  border-radius: 0;
  margin-bottom: 20px;
}
.row-skeleton .react-loading-skeleton {
  width: 235px;
  max-width: 235px;
  margin: auto 10px;
}
.group-banner-img {
  width: 100%;
  height: calc(50vw * 0.8);
  overflow: hidden;
  max-height: 492px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
}
.skeleton-group-banner .react-loading-skeleton {
  height: calc(50vw * 0.8);
  max-height: 492px;
}
.placeholder-group-product-img {
  width: 100%;
  min-height: 152px;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #6d6d6d;
}
@media (max-width: 1366px) {
  .carousel-container {
    margin: 15px auto;
  }
}
@media (max-width: 1310px) {
  .group-product-home {
    max-width: 100%;
    flex: 0 0 32%;
    margin: 0 5px;
    margin-bottom: 10px;
  }
  .group-product-home:not(:nth-child(-n + 9)) {
    display: none;
  }
}
@media (max-width: 1200px) {
  .group-product-price {
    font-size: 1.4rem;
  }
}
@media (max-width: 792px) {
  .group-products-content {
    margin: 30px auto;
  }
}
@media (max-width: 768px) {
  .group-product-home:not(:nth-child(-n + 6)) {
    display: none;
  }
  .group-product-home {
    max-width: 100%;
    flex: 0 0 31%;
    margin: 0 5px;
    margin-bottom: 10px;
  }
  .row-skeleton .react-loading-skeleton {
    width: 100%;
  }
  .row-skeleton > span {
    max-width: 100%;
    flex: 0 0 31%;
    margin: 0 5px;
    margin-bottom: 25px;
    display: flex;
  }
}
@media (max-width: 548px) {
  .group-product-home:not(:nth-child(-n + 4)) {
    display: none;
  }
  .group-product-home {
    max-width: 100%;
    flex: 0 0 47%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .row-skeleton > span {
    max-width: 48%;
    flex: 0 0 48%;
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;
  }
  .row-skeleton .react-loading-skeleton {
    height: 200px !important;
  }
  .carousel.carousel-slider .control-arrow {
    font-size: 200%;
    display: none;
  }
  .carousel .control-dots {
    padding: 0;
    margin-bottom: 0;
  }
  .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
  }
  .group-product-price {
    font-size: 1rem;
  }
  .group-product-name {
    font-size: 15px;
    line-height: 130%;
  }
}
